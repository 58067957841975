import { SubscriptionResponse, SubscriptionRequest, OmitResponse } from "./types";
import { BASE_URL, REACT_APP_OLE_API_KEY } from '../../config';
import { getFlywireCode, uuidv4 } from "services/common";

export const createSubscription = async (
  subscriptionData: SubscriptionRequest,
  sessionToken: string,
  country: string
  ): Promise<SubscriptionResponse> => {
  try {
    const response = await fetch(`${BASE_URL}/api-payments/v1/subscriptions/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': REACT_APP_OLE_API_KEY,
        'X-Session-Token': sessionToken,
        'X-User': 'web',
        'X-Recipient-ID': getFlywireCode(country),
        'X-Request-ID': uuidv4()
      },
      body: JSON.stringify(subscriptionData)
    });

    if (!response.ok) {
      const errorData = await response.json();
      return {
        success: false,
        error: {
          message: `Failed to create subscription: ${response.statusText}`,
          code: `${response.status}`,
          status: response.status,
          details: errorData,
        }
      };
    }

    const data = await response.json();
    return {
      success: true,
      data
    };
  } catch (error) {
    return {
      success: false,
      error: {
        message: `Unexpected error: ${error}`,
        code: 'NETWORK_ERROR'
      }
    };
  }
};

export const omit = async (
  sessionToken: string,
  applicationId: string,
  quotationId: string,
  recipientID: string
  ): Promise<OmitResponse> => {
    try {
      const response = await fetch(`${BASE_URL}/api-payments/v1/subscriptions/omit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': REACT_APP_OLE_API_KEY,
          'X-Session-Token': sessionToken,
          'X-User': 'web',
          'X-Recipient-ID': recipientID,
          'X-Request-ID': uuidv4()
        },
        body: JSON.stringify({
          "id_application": applicationId,
          "id_quotation": quotationId
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        return {
          success: false,
          error: {
            message: `Failed to create subscription: ${response.statusText}`,
            code: `${response.status}`,
            status: response.status,
            details: errorData,
          }
        };
      }
  
      const data = await response.json();
      return {
        success: true,
        data
      };
    } catch (error) {
      return {
        success: false,
        error: {
          message: `Unexpected error: ${error}`,
          code: 'NETWORK_ERROR'
        }
      };
    }
  };

  export const getByQuotation = async (
    quotationId: string
    ): Promise<Boolean> => {
      try {
        const response = await fetch(`${BASE_URL}/api-payments/v1/subscriptions/by-quotation/${quotationId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': REACT_APP_OLE_API_KEY,
            'X-User': 'web',
            'X-Request-ID': uuidv4()
          },
        });
    
        if (!response.ok) {
          /*const errorData = await response.json();
          return {
            success: false,
            error: {
              message: `Failed to get subscription: ${response.statusText}`,
              code: `${response.status}`,
              status: response.status,
              details: errorData,
            }
          };*/
          return false;
        }
    
        /*const data = await response.json();
        return {
          success: true,
          data
        };*/
        return true;
      } catch (error) {
        /*return {
          success: false,
          error: {
            message: `Unexpected error: ${error}`,
            code: 'NETWORK_ERROR'
          }
        };*/
        return false;
      }
    };