import { REACT_APP_FLYWIRE_CODE_OTHER, REACT_APP_FLYWIRE_CODE_US } from "config";

const UNITED_STATES = 'US';

const uuidv4 = () => {
    return "10000000100040008000100000000000".replace(/[018]/g, c =>
      ((+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & 15)) >> (+c / 4)).toString(16)
    );
}

const getFlywireCode = (country:string) => {
  let flywireCode = REACT_APP_FLYWIRE_CODE_OTHER;
  if (country === UNITED_STATES) {
    flywireCode = REACT_APP_FLYWIRE_CODE_US
  }
  return flywireCode;
}

export { uuidv4, getFlywireCode };