import { getFlywireCode, uuidv4 } from 'services/common';
import { BASE_URL, REACT_APP_OLE_API_KEY } from '../../config';
import { CheckoutRequest, CheckoutResponse } from "./types";


export const checkout = async (
  data: CheckoutRequest,
  country: string,
  ): Promise<CheckoutResponse> => {
  
  try {
    const response = await fetch(`${BASE_URL}/api-payments/v1/payments/checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': REACT_APP_OLE_API_KEY,
        'X-User': 'web',
        'X-Recipient-ID': getFlywireCode(country),
        'X-Request-ID': uuidv4()
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      const errorData = await response.json();
      return {
        success: false,
        error: {
          message: `Failed to checkout: ${response.statusText}`,
          code: `${response.status}`,
          status: response.status,
          details: errorData,
        }
      };
    }
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error: {
        message: `Unexpected error: ${error}`,
        code: 'NETWORK_ERROR'
      }
    };
  }
};