import React, {useContext, useEffect, useState} from 'react';
import Header from '../components/Header';
import OleCheckbox from '../components/OleCheckbox';
import FormFlyware from '../components/FormFlyware';
import LinkOptionContext from '../contexts/LinkOptionContext';
import { languageMessages } from 'traductor/PaymentLink';
import { useLocation } from 'react-router-dom';
import { LinkResponse, TransformedData, transformLinkResponse } from 'services/link/types';
import { getHashInfo } from 'services/link/linkService';
import Dialog from '../components/OleDialog';
import OlePopupInformation from '../components/OlePopupInformation';
import Skeleton from '../components/Skeleton';
import PageConfirmation from './PageConfirmation';
import { checkout } from 'services/checkout/checkoutService';
import { CheckoutRequest } from 'services/checkout/types';
import { CardUpdateRequest } from 'services/card/types';
import { updateCard } from 'services/card/CardService';
import LanguageContext from 'contexts/LanguageContext';
import FormPersonalData from 'components/FormPersonalData';
import '../styles/PaymentLink.css'
import Layout from 'Layout';
import { REACT_APP_TIME_REFRESH } from 'config';

function PaymentLink({language}: {language: string}) {
    const [token, setToken] = useState('');
    // eslint-disable-next-line
    const [selectedLinkOption, setSelectedLinkOption] = useState(localStorage.getItem('selectedLinkOption') || 'payment');
    const location = useLocation();
    const [sessionLoaded, setSessionLoaded] = useState(false);
    const [sessionData, setSessionData] = useState<LinkResponse | null>(null);
    const [objectData, setObjectData] = useState<any>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenInformation, setIsOpenInformation] = useState(false);
    
    const [success, setSuccess] = useState(false);
    const [status, setStatus] = useState<string>();
    const [result, setResult] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const [retry, setRetry] = useState(-1);
    const [stepActive, setStepActive] = useState(true);
    const [informationPayment, setInformationPayment] = useState<Map<string, string>>(new Map());
    const [informationUpdate, setInformationUpdate] = useState<Map<string, string>>(new Map());
    // eslint-disable-next-line
    const { changeLanguage } = useContext(LanguageContext);
    const RECURRENT_ALWAYS = true
    const [INSURED_TEXT, setInsuredText] = useState(languageMessages(language, 'insured'))
    const [POLICY_OWNER_TEXT, setPolicyOwnerText] = useState(languageMessages(language, 'policyOwner'))
    const [POLICY_NUMBER_TEXT, setPolicyNumberText] = useState(languageMessages(language, 'policyNumber'))
    // eslint-disable-next-line
    const [PLAN, setPlan] = useState(languageMessages(language, 'plan'))
    // eslint-disable-next-line
    const [TERM, setTerm] = useState(languageMessages(language, 'term'))
    // eslint-disable-next-line
    const [PREMIUM, setPremium] = useState(languageMessages(language, 'premium'))
    const [isVantage, setIsVantage] = useState(false);

    const responseflywire = async(dato:any) => {
        if (dato.success) {
            console.log('flywire callback', dato);
            setRetry(-1);
            setSuccess(true);
            setResult(dato?.args);
            setStatus(dato?.args.status)
            try {
                const ids = objectData.dues.filter((due: any) => due.checked).map((due: any) => { return due.id; });
                if (parseFloat(dato?.args.amount) > 0) {
                    if (dato?.args.paymentMethod === 'card' || dato?.args.paymentMethod === 'credit_card' || dato?.args.paymentMethod === 'recurring') {
                        let recurrentOption = objectData.recurrent;
                        if (dato?.args.token !== '') {
                            recurrentOption = true;
                        }
                        const checkoutData:CheckoutRequest = {
                        application_id: objectData.idApplication,
                        card: {
                            "brand": dato?.args.brand,
                            "digits": dato?.args.digits,
                            "expirationMonth": dato?.args.expirationMonth,
                            "expirationYear": dato?.args.expirationYear,
                            "sig": dato?.args.sig,
                            "token": dato?.args.token,
                            "type": dato?.args.type,
                            "recurrent": recurrentOption,
                        },
                        "ids": ids,
                        "reference": dato?.args.reference,
                        "amount_from": dato?.args.payerAmount,
                        "amount_to": parseFloat(dato?.args.amount),
                        "currency_from": dato?.args.payerAmountCurrency,
                        "currency_to": dato?.args.amountCurrency,
                        "paymentMethod": dato?.args.paymentMethod,            
                        "status": dato?.args.status,
                        payer: {
                            first_name: objectData.payerFirstName,
                            last_name: objectData.payerLastName,
                            middle_name: objectData.payerMiddleName,
                            address1: objectData.address,
                            address2: '',
                            city: objectData.city || objectData.cityForFlywire,
                            state: objectData.state,
                            zip: objectData.zipCode,
                            country: objectData.country,
                            phone: objectData.phone,
                            email: objectData.email,
                            paymaster: objectData.payingForPolicy,
                            paymaster_relationship: objectData.relationship,
                        },
                        };
                        console.log(`checkout(${objectData.country})`, checkoutData);
                        const res = await checkout(checkoutData, objectData.country);
                        if (res.success) {
                            console.log('checkout (response)', 'success');
                        } else {
                            console.error('checkout (response)', res.error);
                        }
                    } else {
                        console.warn('checkout', 'paymentMethod not supported');
                    }
                } else {
                    const cardData:CardUpdateRequest = {
                      brand: dato?.args.brand,
                      digits: dato?.args.digits,
                      expirationMonth: dato?.args.expirationMonth,
                      expirationYear: dato?.args.expirationYear,
                      sig: dato?.args.sig,
                      token: dato?.args.token,
                      type: dato?.args.type,
                      recurrent: RECURRENT_ALWAYS,
                      payer: {
                        first_name: objectData.payerFirstName,
                        last_name: objectData.payerLastName,
                        middle_name: objectData.payerMiddleName,
                        address1: objectData.address,
                        address2: '',
                        city: objectData.city || objectData.cityForFlywire,
                        state: objectData.state,
                        zip: objectData.zipCode,
                        country: objectData.country,
                        phone: objectData.phone,
                        email: objectData.email,
                        paymaster: objectData.payingForPolicy,
                        paymaster_relationship: objectData.relationship,
                      }
                    };
                    console.log(`updateCard(${objectData.country})`, cardData);
                    const res = await updateCard(cardData, objectData.country, token);
                    if (res.success) {
                        console.log('updateCard(response)', res);
                    } else {
                        console.error('updateCard(response)', res.error);
                    }
                }
            } catch (error) {
                setIsOpen(true);
                console.error('flywire callback', error);
            }
        }
    }

    useEffect(() => {
        const newData: Partial<TransformedData> = { language: language };
        setObjectData((prevData: any) => ({ ...prevData, ...newData }));
        const A1 = languageMessages(language, 'insured')
        //const A2 = languageMessages(language, 'policyOwner')
        const A3 = languageMessages(language, 'policyNumber')
        const A5 = languageMessages(language, 'term')
        const A6 = languageMessages(language, 'premium')
        const A7 = languageMessages(language, "frequency")
        setInsuredText(languageMessages(language, 'insured'))
        setPolicyOwnerText(languageMessages(language, 'policyOwner'))
        setPolicyNumberText(languageMessages(language, 'policyNumber'))
        setPlan(languageMessages(language, 'plan'))
        setTerm(languageMessages(language, 'term'))
        setPremium(languageMessages(language, 'premium'))
        setInformationPayment(new Map(Object.entries(
            {
                [A1]: sessionData?.first_name + ' ' + sessionData?.last_name,
                //[A2]: sessionData?.first_name + ' ' + sessionData?.last_name,
                [A3]: sessionData?.id_application || '',
            }
        )));
        setInformationUpdate(new Map(Object.entries(
            {
                [A1]: sessionData?.first_name + ' ' + sessionData?.last_name || '',
                //[A2]: sessionData?.first_name + ' ' + sessionData?.last_name || '',
                [A3]: sessionData?.id_application || '',
                [A5]: sessionData?.term + ' ' + A7[sessionData?.frequency || ''] || '',
                [A6]: sessionData?.currency + ' $' + sessionData?.payments[0].amount || '',
            }
        )));
        // eslint-disable-next-line
    }, [language]);

    useEffect(() => {
        document.documentElement.style.setProperty('--fwpDisplayForm', 'none');
        document.documentElement.style.setProperty('--fwpDisplayData', 'block');

        const queryParams = new URLSearchParams(location.search);
        const sessionToken = queryParams.get('subscription') ?? '';
        const originVantage = queryParams.get('origin') ?? '';
        
        setToken(sessionToken);
        setIsVantage(originVantage === 'app-mobile');
        if (!localStorage.getItem('selectedLinkOption')) {
            localStorage.setItem('selectedLinkOption', 'payment');
            changeOption('payment');
        }
    
        if (!sessionToken || sessionLoaded) {
          if (!sessionToken) {
            console.error('session', languageMessages(language, 'errorMessages')['payment'].subtitle);
            setError({
                title: languageMessages(language, 'errorMessages')['payment'].title,
                subtitle: languageMessages(language, 'errorMessages')['payment'].subtitle,
                buttonText: languageMessages(language, 'errorMessages')['payment'].buttonText
            });
            setIsOpen(true);
          }
          return;
        }

        const fetchData = async () => {
            try {
                const session: LinkResponse = await getHashInfo(sessionToken);
                if (session.payments === undefined || session.payments.length === 0) {
                    setError({
                        title: languageMessages(language, 'errorMessages')['notPendingPayments'].title,
                        subtitle: languageMessages(language, 'errorMessages')['notPendingPayments'].subtitle,
                        buttonText: languageMessages(language, 'errorMessages')['notPendingPayments'].buttonText
                    });
                    setIsOpen(true);
                    console.warn('session', languageMessages(language, 'errorMessages')['notPendingPayments'].subtitle);
                    return;
                }
                setSessionData(session);
                setObjectData(transformLinkResponse(session));
                const languageMap: { [key: string]: string } = {
                    EN: 'en',
                    US: 'en',
                    SP: 'es',
                    PT: 'pt',
                    ES: 'es',
                };
                changeLanguage(languageMap[session.language]);
                const A1 = languageMessages(languageMap[session.language], 'insured')
                //const A2 = languageMessages(languageMap[session.language], 'policyOwner')
                const A3 = languageMessages(languageMap[session.language], 'policyNumber')
                const A5 = languageMessages(languageMap[session.language], 'term')
                const A6 = languageMessages(languageMap[session.language], 'premium')
                const A7 = languageMessages(languageMap[session.language], "frequency")
                setInsuredText(languageMessages(languageMap[session.language], 'insured'))
                setPolicyOwnerText(languageMessages(languageMap[session.language], 'policyOwner'))
                setPolicyNumberText(languageMessages(languageMap[session.language], 'policyNumber'))
                setPlan(languageMessages(languageMap[session.language], 'plan'))
                setTerm(languageMessages(languageMap[session.language], 'term'))
                setPremium(languageMessages(languageMap[session.language], 'premium'))
                setInformationPayment(new Map(Object.entries(
                    {
                        [A1]: session?.first_name + ' ' + session?.last_name,
                        //[A2]: session?.first_name + ' ' + session?.last_name,
                        [A3]: session?.id_application || '',
                    }
                )));
                setInformationUpdate(new Map(Object.entries(
                    {
                        [A1]: session?.first_name + ' ' + session?.last_name || '',
                        //[A2]: session?.first_name + ' ' + session?.last_name || '',
                        [A3]: session?.id_application || '',
                        [A5]: session?.term + ' ' + A7[session?.frequency || ''] || '',
                        [A6]: session?.currency + ' $' + session?.payments[0].amount || '',
                    }
                )));
            } catch (error) {
                console.error('session', JSON.stringify(error));
                setError({
                    title: languageMessages(language, 'errorMessages')['session'].title,
                    subtitle: languageMessages(language, 'errorMessages')['session'].subtitle,
                    buttonText: languageMessages(language, 'errorMessages')['session'].buttonText
                });
                setIsOpen(true);
                //console.error('Error getting session:', error);
            } finally {
                setSessionLoaded(true);
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, [sessionData, objectData, location.search, sessionLoaded, language, changeLanguage, INSURED_TEXT, POLICY_OWNER_TEXT, POLICY_NUMBER_TEXT]);

    useEffect(() => {
        const fetchData = async () => {
            const queryParams = new URLSearchParams(location.search);
            const sessionToken = queryParams.get('subscription') ?? '';
            const session: LinkResponse = await getHashInfo(sessionToken);
            if (session.payments === undefined || session.payments.length === 0) {
                setError({
                    title: languageMessages(language, 'errorMessages')['notPendingPayments'].title,
                    subtitle: languageMessages(language, 'errorMessages')['notPendingPayments'].subtitle,
                    buttonText: languageMessages(language, 'errorMessages')['notPendingPayments'].buttonText
                });
                setIsOpen(true);
                console.warn('session', languageMessages(language, 'errorMessages')['notPendingPayments'].subtitle);
                return;
            }
            setSessionData(session);
            setObjectData(transformLinkResponse(session));
        }
        fetchData();
    // eslint-disable-next-line
    }, [success]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const option = queryParams.get('option') ?? '';
        if (option === 'update') {
            setSelectedLinkOption('update');
            localStorage.setItem('selectedLinkOption', 'update');
        }
        // eslint-disable-next-line
    }, []);

    const handleCheckboxChange = (id: number) => {
        const updatedDues = objectData?.dues.map((due:any, index:number) => {
            if (index > 0 && due.id === id) {
                due.checked = !due.checked;
                if (index < objectData.dues.length - 1) {
                    objectData.dues[index + 1].disabled = !due.checked;
                    objectData.dues[index + 1].checked = false;
                    for (let i = index + 2; i < objectData.dues.length; i++) {
                        objectData.dues[i].checked = false;
                        objectData.dues[i].disabled = true;
                    }
                }
            }
            return due;
        });
        const total = updatedDues?.reduce((acc:any, due:any) => {
            if (due.checked) {
                acc += parseFloat(due.premium.toString());
            }
            return acc;
        }, 0);
        let roundedTotal = parseFloat(total.toFixed(2));
        const ids = objectData.dues.filter((due: any) => due.checked).map((due: any) => { return due.id; });
        const newData: Partial<TransformedData> = { dues: updatedDues ?? [], amount: roundedTotal, paymentIds: ids };
        setObjectData((prevData: any) => ({ ...prevData, ...newData }));
    };

    const changeOption = (newOption: React.SetStateAction<string>) => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has('option')) {
            queryParams.delete('option');
            window.history.replaceState({}, '', `${location.pathname}?${queryParams}`);
        }
        
        localStorage.setItem('selectedLinkOption', newOption.toString());
        window.location.reload();
    };

    const callFormPayment = () => {
        let _object = objectData;
        setObjectData(_object);
        setRetry(retry + 1);
    };

    const handleClose = () => {
        setIsOpen(false);
    };
    
    const handleButtonClick = () => {
        window.location.reload();
    };

    const handleButtonContinue = () => {
        setStepActive(false);
    }

    const handleButtonReturn = () => {
        if (isVantage) {
            try {
                //@ts-ignore
                window.ReactNativeWebView.postMessage("session-back");
            } catch (error) {
                console.error('session-back', error);
            }
            return;
        }
    }

    const handleBackStep = () => {
        setStepActive(true);
    }

    const showInformation = () => {
        setIsOpenInformation(true);
    }

    const onInactive = () => {
        console.warn('activity', 'User inactive');
        window.location.href = '/';
    }

    return (
        <Layout inactivityTime={Number.parseInt(REACT_APP_TIME_REFRESH)} onInactive={onInactive}>
        <LinkOptionContext.Provider value={{ option: selectedLinkOption, changeOption }}>
            {isOpen && (
            <Dialog
                title={error===null ? '' : error.title}
                subtitle={error===null ? '' : error.subtitle}
                buttonText={error===null ? '' : error.buttonText}
                onClose={handleClose}
                onButtonClick={handleButtonClick}
                showClose={false}
                />
            )}
            {isOpenInformation && (
            <OlePopupInformation
                information={selectedLinkOption === 'payment' ? informationPayment : informationUpdate}
                title={languageMessages(language, 'policyInformation')}
                onClose={() => setIsOpenInformation(false)}
            />
            )}
            {sessionLoaded ? (
            <div className='app'>
                <Header 
                    paymentType='link' 
                    language={language} 
                    showLogo={true} 
                />
                <div className='payment-header-link' style={selectedLinkOption === 'payment' && !success ? {display : 'block'} : {display: 'none'}}>
                    <div className=''>
                        <div className='return' style={isVantage ? {display: 'block'}: {display: 'none'}}>
                            <div><img src='link-return.svg' alt='return' onClick={handleButtonReturn} /></div>
                        </div>
                        <div className='step'>
                            <div className='step-1' style={ stepActive ? {display: 'block'} : {display: 'none'}}>
                                <img src='link-step-1.svg' alt='step1' />
                            </div>
                            <div className='step-2' style={ !stepActive ? {display: 'block'} : {display: 'none'}}>
                                <img src='link-step-2.svg' alt='step2' onClick={handleBackStep} />
                            </div>
                        </div>
                    </div>
                    <span className='title' style={ stepActive ? {display: 'block'} : {display: 'none'}}>{languageMessages(language, 'titleStep1')}</span>
                    <span className='title' style={ !stepActive ? {display: 'block'} : {display: 'none'}}>{languageMessages(language, 'titleStep2')}</span>
                    <div className='paymentInfo' style={ !stepActive ? {display: 'block'} : {display: 'none'}}>
                        <div className='description'>{languageMessages(language, "totalPayment")}</div>
                        <div className='amount'> {objectData?.currency} {objectData?.symbol}{objectData?.amount}<span></span></div>
                    </div>
                </div>
                <div className = { selectedLinkOption === 'payment' ? 'information-t1' : 'information-t2' } style={success ? {display: 'none'} : {}}>
                    <div style={!stepActive ? {display: 'none'} : {display: 'block'}}>
                        <img src='link-info.svg' alt='information' />
                    </div>
                    <div  style={!stepActive ? {display: 'none'} : {display: 'block'}} className='click' onClick={showInformation}>&nbsp;&nbsp;{languageMessages(language, 'policyInformation')}</div>
                </div>
                <div className='payment-body-link' style={!success ? { display: 'flex' } : { display: 'none' }}>
                    <div className={selectedLinkOption === 'payment' ? 'payment-body-table' : 'payment-body-table-update'} style={ stepActive ? {display: 'block'} : {display: 'none'}}>
                        <div className='payment-data-link'>
                            <div className='title' style = {selectedLinkOption === 'payment' ? { display: 'none' } : { display: 'block' }}>
                                <span style={{ fontWeight: 'bold' }}><strong>{languageMessages(language, 'policyInformation')}</strong></span>
                            </div>
                            <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'insured')}</span> {objectData?.insured}
                            </div>
                            <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'policyNumber')}</span> {objectData?.policyNumber}
                            </div>
                            <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'term')}</span> {objectData?.term}
                            </div>
                            <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'period')[objectData?.paymentFrequency]}</span> {objectData?.premium}
                            </div>
                        </div>
                    </div>
                    <div className='payment-form-link' style={ stepActive ? {display: 'flex'} : {display: 'none'}}>                        
                        <div className='payment-quotes-link' style={selectedLinkOption === 'update' ? { display: 'none' } : { display: 'block' }}>
                            <div className='payment-data-link'>
                                <div className='title' style = {selectedLinkOption === 'payment' ? { display: 'none' } : { display: 'block' }}>
                                    <span style={{ fontWeight: 'bold' }}><strong>{languageMessages(language, 'policyInformation')}</strong></span>
                                </div>
                                <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                    <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'insured')} </span> {objectData?.insured}
                                </div>
                                <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                    <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'policyNumber')} </span> {objectData?.policyNumber}
                                </div>
                                <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                    <span style={{ fontWeight: 'bold' }}>{languageMessages(language, 'term')} </span> {objectData?.term} {languageMessages(language, 'frequency')[objectData?.paymentFrequency]}
                                </div>
                                <div className='row' style={selectedLinkOption === 'payment' ? { paddingTop: '10px', paddingBottom: '10px' } : { paddingTop: '15px', paddingBottom: '15px' }}>
                                    <span style={{ fontWeight: 'bold' }}>{ languageMessages(language, 'period')[objectData?.paymentFrequency] }:</span> {objectData?.premium}
                                </div>
                            </div>
                            <div className='table'>
                                <table cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{languageMessages(language, 'tableTitle2')}</th>
                                            <th>{languageMessages(language, 'tableTitle3')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {objectData?.dues && (
                                            objectData?.dues.map((row:any) => (
                                                <tr key={row.id}>
                                                    <td>
                                                        <OleCheckbox id={row.id} checked={row.checked} onChange={handleCheckboxChange} disabled={row.disabled} text='' />
                                                    </td>
                                                    <td>{row.dueDate}</td>
                                                    <td style={row.withDelay ? { color: '#D00', fontWeight: 'bold' } : { color: '#00D090', fontWeight: 'bold' }}>{row.currency} {row.symbol}{row.premium}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className='confirmation_continue'>
                                <span className='title'>{languageMessages(language, 'totalPayment')}</span>
                                <span className='amount'>{(objectData?.currency + ' ' + objectData?.symbol + objectData?.amount).replaceAll('undefined', '')}</span>
                                <div className='button' onClick={handleButtonContinue}>{languageMessages(language, 'continueButton')}</div>
                            </div>
                        </div>
                        <div style={selectedLinkOption === 'update' ? { display: 'block' } : { display: 'none' }}>
                            {objectData && <FormPersonalData objectData={objectData} onClick={callFormPayment} language={language} buttonText={selectedLinkOption === 'payment' ? languageMessages(language, "payNowAprroved") : languageMessages(language, "updateCard")} showRecurrent = {false} />}
                            <div className='authorize'>
                                <div className='message'>{languageMessages(language, "authorizeMessage")}</div>
                            </div>
                        </div>
                    </div>
                    <div className='data' style={ !stepActive ? {display: 'block'} : {display: 'none'}}>
                        {objectData && <FormPersonalData objectData={objectData} onClick={callFormPayment} language={language} buttonText={selectedLinkOption === 'payment' ? languageMessages(language, "payNowAprroved") : languageMessages(language, "updateCard")} showRecurrent />}
                    </div>
                </div>
                <div style={success ? { display: 'block' } : { display: 'none' }}>
                    <PageConfirmation 
                        language = {language} 
                        firstname = {objectData?.firstName} 
                        amount = {objectData?.currency + ' ' + objectData?.symbol +  objectData?.amount} 
                        policy = {objectData?.policyNumber} 
                        type = {selectedLinkOption} 
                        card = {result?.digits} 
                        date = {objectData?.dues ? objectData?.dues[0].dueDate : ''}
                        isComplete = {status === 'success'}
                    />
                </div>
            </div>
            ) : (
                <Skeleton />
            )}
            <FormFlyware 
                objectData={objectData} 
                isModal={true} 
                retry={retry} 
                session={token} 
                onCompleteFlywire={responseflywire} 
            />
        </LinkOptionContext.Provider>
        </Layout>
    );
}

export default PaymentLink;