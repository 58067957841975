import { CardUpdateRequest, CardUpdateResponse } from "./types";
import { REACT_APP_OLE_API_KEY } from '../../config';
import { BASE_URL } from '../../config';
import { getFlywireCode, uuidv4 } from "services/common";

export const updateCard = async (
  request: CardUpdateRequest,
  country: string,
  hash: string,
  ): Promise<CardUpdateResponse> => {
  try {
    const response = await fetch(`${BASE_URL}/api-payments/v1/subscriptions/card/${hash}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': REACT_APP_OLE_API_KEY,//TODO: Corregir
        'X-User': 'web',
        'X-Recipient-ID': getFlywireCode(country),
        'X-Request-ID': uuidv4()
      },
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      const errorData = await response.json();
      return {
        success: false,
        error: {
          message: `Failed to update card: ${response.statusText}`,
          code: `${response.status}`,
          status: response.status,
          details: errorData,
        }
      };
    }
    return {
      success: true,
    };
    //return await response.json();
  } catch (error) {
    return {
      success: false,
      error: {
        message: `Unexpected error: ${error}`,
        code: 'NETWORK_ERROR'
      }
    };
  }
};